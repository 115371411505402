<template>
  <div>
    <BrandChooseWindow />

    <Header>
      <img slot="logo" src="../assets/images/logo/logo-all-dark.png" />
    </Header>

    <div class="bg_image bg_image--16 d-none d-md-block">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="rn-page-title text-center pt--100"></div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="bg-img"></div>

    <div class="reward-content">
      <div class="reward-content__info-area">
        <h2>Ποιές είναι οι βαθμίδες & οι πόντοι</h2>

        <p class="reward-content__text">
          Αφού κατεβάσετε την εφαρμογή Health_e Bonus Card γίνεστε αυτόματα
          κάτοχος της Health_e Bonus White, η οποία έχει 0 πόντους.
        </p>
        <p class="reward-content__text">
          Όταν μαζέψετε 1000 πόντους αλλάζετε αυτομάτως βαθμίδα και αποκτάτε την
          Health_e Bonus Blue.
        </p>
        <p class="reward-content__text">
          Όταν φτάσετε τους 8.000 πόντους ανεβαίνετε στην Health_e Bonus Silver.
        </p>
        <p class="reward-content__text">
          Όταν φτάσετε τους 40.000 πόντους αποκτάτε την Health_e Bonus Gold.
        </p>

        <h3>Ποια είναι η αντιστοιχία ευρώ - πόντων</h3>

        <p class="reward-content__text">
          Πρωτοβάθμιες υπηρεσίες: 1 ευρώ = 10 πόντοι<br />
          Διαγνωστικές εξετάσεις στα HealthSpot: 1 ευρώ = 20 πόντοι<br />
          Ιδιωτική νοσηλεία/νοσηλεία με χρήση δημόσιου ασφαλιστικού φορέα: 1 ευρώ = 3 πόντοι<br />
          Νοσηλεία με ιδιωτική ασφάλιση: 1 ευρώ = 1 πόντος
        </p>

        <h3>Φόρτωση πόντων</h3>

        <div>
          <p>
            Οι πόντοι πιστώνονται στο λογαριασμό σας εφόσον και αν έχετε
            κατεβάσει την εφαρμογή, δίνοντας τον αριθμό του κινητού σας 
            τηλεφώνου στο ταμείο του θεραπευτηρίου που εξυπηρετηθήκατε.
          </p>
          <p>
            Από τη στιγμή της ενεργοποίησης της health e-bonus card έχετε την
            δυνατότητα έως και τρεις μήνες αργότερα να ζητήσετε να πιστωθούν οι
            πόντοι σας για υπηρεσίες που έχετε εξοφλήσει.
          </p>
          <p>
            π.χ
            <b>ενεργοποίηση κάρτας:</b>
            20/3/22 ,
            <b>εξέταση</b>
            : 25/3/22 εάν δεν λάβατε πόντους την ίδια μέρα, μπορείτε να ζητήσετε
            να σας πιστώσουν τους πόντους σας εντός 3 μηνών από τη στιγμή που
            πραγματοποιήσατε την εξέταση.
          </p>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/header/HeaderOnePage";
import BrandChooseWindow from "../components/brand/BrandChooseWindow";
import Footer from "../components/footer/FooterTwo";
export default {
  components: {
    Header,
    BrandChooseWindow,
    Footer,
  },
};
</script>
<style scoped>
.bg-img {
  width: 100%;
  height: 345px;
  background-image: url("../assets/images/bg/bg-image-151.png");
  background-position-y: -300px;
  background-repeat: no-repeat;
  background-size: cover;
}

.reward-content {
  padding: 40px 200px;
}

.reward-content__info-area {
  width: 60%;
}

.reward-content__text {
  font-weight: 600;
  font-size: 15px;
}
</style>
